
import { defineComponent } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
const axios = require("axios").default;

export default defineComponent({
	setup()
	{
		return {v$: useVuelidate()};
	},
	data()
	{
		return {
			name: "",
			surname: "",
			email: "",
		};
	},
	validations()
	{
		return {
			name: { required },
			surname: { required },
			email: { required }
		};
	},
	methods: {
		createUser(): void
		{
			this.v$.$touch();
			if (this.v$.$error)
			{
				alert("there are form's errors");
				return;
			}
			let payload = {
				name: this.name,
				surname: this.surname,
				email: this.email
			};
			axios.post(process.env.VUE_APP_HOST + "/client", payload)
				.then((response: any) =>
				{
					if (response.status == 200)
					{
						alert("New client is succesfully created");
					}
				})
				.catch(function (error: any)
				{
					console.log(error.response);
					if (error.response.status == 409)
					{
						alert("Client with identical email already exists. Look for it.");
					}
				});
		}
	}
});


