
import { defineComponent } from "vue";
import CreateClient from "@/components/CreateClient.vue";

export default defineComponent({
	name: 'Client',
	components: {
		CreateClient
	}
});
